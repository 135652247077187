::selection {
    background: $color-red-light;
    color: $color-white;
}

h1, h2, h3, h4, p, a, ul, li{
	margin: 0;
}

h1{
    font-size: 80px;
    text-transform: uppercase;
	@include tablet-up{
		font-size: 170px;
		font-family: 'Oswald', sans-serif;
	}
}

h2{
    text-transform: uppercase;
    font-size: 40px;
    letter-spacing: .1em;
    font-weight: 400;
	@include tablet-up{
		font-size: 150px;
		font-family: 'Oswald', sans-serif;
	}
}

.text-vertical{
	writing-mode: vertical-rl;
    &.reverse{
	    transform: rotate(180deg);
    }
}