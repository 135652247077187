@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "buttons";
@import "typography";

@font-face {
	font-family: 'Bastrad Vol.02';
	src: url('../../dist/font/BastradVol02-Regular.woff2') format('woff2'),
		url('../../dist/font/BastradVol02-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'a Another Tag';
	src: url('../../dist/font/aAnotherTag.woff2') format('woff2'),
		url('../../dist/font/aAnotherTag.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'a Another Tag';
	src: url('../../dist/font/aAnotherTag.woff2') format('woff2'),
		url('../../dist/font/aAnotherTag.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

html {
	background: $color-black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

.desktop {
	display: none;
}

@include tablet-up {
	.mobile {
		display: none;
	}

	.desktop {
		display: block;
	}
}

header {
	min-height: 100vh;
	padding: 20px 0;
	background-image: url('../../dist/img/bg/PLATAFORMAS01_NAIKI_128.jpeg');
	background-color: $color-black;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	@include flex(column, flex-start, center);
	gap: 20px;

	.shop-link {
		position: absolute;
		left: 50px;
		top: 23px;
		display: inline-block;
		font-size: 18px;
		font-weight: 700;
		color: $color-white;

		@include hover-focus {
			opacity: .7;
		}

		@include tablet-down {
			left: 20px;
			font-size: 14px
		}
	}

	.social {
		@include flex(row, center, center);
		margin-right: 10px;

		a {
			font-size: 18px;
			margin-right: 10px;
			transition: all .2s;

			&:last-child {
				margin-right: 0;
			}

			@include hover-focus {
				transform: scale(1.2);
			}
		}
	}

	.logo {
		width: 100%;
		max-width: 150px;
		align-self: flex-start;
		margin-left: 10px;
	}

	@include tablet-up {
		padding: 30px 40px;
		// add these back if returning to half screen layout
		// background-size: contain;
		// background-position: right bottom;
		@include flex(row, inherit, center);

		.social {
			flex-direction: column;
			margin-right: 60px;

			a {
				font-size: 24px;
				margin-right: 0;
				margin-bottom: 20px;
			}
		}

		.logo {
			width: 400px;
			max-width: 50%;
			margin: 0;
		}
	}
}

main {

	@include tablet-down {
		// padding-top: 100px;

		&.tour-main {
			padding-top: 0
		}
	}

	.container {
		&.split {
			@include tablet-up {
				@include flex(row, space-between, inherit);
			}
		}
	}

	section.music {
		background-color: $color-gray;
		// background-color: $color-white;
		padding: 40px 0;
		position: relative;

		.section-heading {
			color: $color-white !important;
			position: relative;
			text-align: center;
		}

		.container {
			position: relative;
			background-color: $color-gray;

			.side {
				&:first-child {
					h2 {
						text-align: center;
						margin-bottom: 20px;
					}

					margin-bottom: 20px;

					img {
						display: block;
						margin: 0 auto;
						width: 100%;
						max-width: 534px;
						max-height: 534px;
						height: 100%;
					}
				}

				&:last-child {
					.links {
						.btn {
							display: block;
							margin: 0 auto 20px;
						}
					}
				}
			}
		}

		@include tablet-up {
			padding: 80px 0 200px 0;

			.container {
				background-color: transparent;

				&::before {
					content: "";
					display: block;
					position: absolute;
					width: 100%;
					height: 100%;
					left: 0;
					top: 60px;
					background-color: $color-gray;
				}

				@include flex(row, center, flex-end);
				gap: 40px;

				.side {
					position: relative;
					flex: 1;
					max-width: max-content;

					&:first-child {
						margin-bottom: 0;
					}

					&:last-child {
						@include flex(row, flex-end, center);

						.links {
							@include flex(column, flex-end, flex-end);
							gap: 10px;

							.btn {
								font-size: 16px;
								text-align: left;
								margin: 0;
								border-bottom: solid 1px $color-white;

								@include hover-focus {
									color: $color-white !important;
								}
							}
						}
					}
				}
			}
		}
	}

	section.bio {
		background-image: url('../../dist/img/refresh/nn-about-image.jpg');
		background-color: $color-black;
		background-size: cover;
		background-position: top left;
		background-repeat: no-repeat;
		padding: 40px 0;
		position: relative;

		&::before {
			content: "";
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.281);
			position: absolute;
			top: 0;
			left: 0;
			transition: all .3s;
		}

		.container {
			position: relative;

			.wrapper {
				h2 {
					margin-bottom: 20px;
				}

				.buttons {
					margin-bottom: 20px;
					border-bottom: solid 1px $color-white;
					padding-bottom: 10px;
					@include flex(row, flex-end, center);
					display: none;

					a {
						font-size: 13px;
						text-align: right;
						color: $color-black;

						&:first-child {
							margin-right: 10px;
						}

						&:last-child {
							margin-left: 10px;
						}

						@include hover-focus {
							color: $color-white;
						}

						&.active {
							color: $color-white;
						}
					}
				}

				.bio-content {
					display: none;

					&.active {
						display: block;
					}

					p {
						margin-bottom: 20px;
						line-height: 1.6em;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		@include tablet-up {
			padding: 120px 0;
			background-size: contain;

			&::before {
				opacity: .5;
			}

			.container {
				@include flex(row, flex-end, inherit);

				.wrapper {
					max-width: 540px;

					h2 {
						margin-bottom: 60px;
					}
				}
			}
		}
	}

	section.videos {
		background-color: $color-white;
		color: $color-black;
		padding: 40px 0;
		position: relative;

		&::before {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: $color-white;
			background-image: url('../../dist/img/refresh/nn-graffiti-bg-image.jpg');
			background-size: cover;
			background-position: top center;
			background-repeat: no-repeat;
			opacity: .4;
		}

		.container {
			position: relative;

			h2 {
				text-align: center;
				margin-bottom: 20px;
			}

			.wrapper {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.video {
					margin-bottom: 40px;

					iframe {
						margin-bottom: 20px;
					}

					.info {
						p {
							text-align: center;
							letter-spacing: .4em;
							text-transform: uppercase;

							&:first-child {
								margin-bottom: 10px;
							}
						}
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.btn-wrapper {
				padding-top: 100px;
				display: flex;
				justify-content: center;

				.btn {
					background-color: $color-black;
					color: $color-white;

					@include hover-focus {
						background-color: $color-gray;
					}
				}
			}
		}

		@include tablet-up {
			padding: 100px 0;

			.container {
				h2 {
					text-align: center;
					margin-bottom: 80px;
				}

				.wrapper {
					display: block;

					.video {
						@include flex(row, center, center);
						margin-bottom: 60px;
						position: relative;

						iframe {
							flex: 1;
							height: 340px;
							max-width: 600px;
							margin-bottom: 0;
						}

						.info {
							flex: 1;
							max-width: 380px;
						}

						&:nth-child(even) {
							flex-direction: row-reverse;
							z-index: 99;
						}

						&:first-child {
							.info {
								padding-bottom: 40px;
							}
						}

						&:last-child {
							.info {
								padding-top: 20px;
							}
						}
					}
				}
			}
		}
	}

	section.tour {
		background-image: url("../img/bg/nn-tour.jpg");
		background-position: center left;
		background-repeat: no-repeat;
		background-size: 100% auto;
		min-height: 100vh;
		padding-top: 10%;
		display: flex;
		align-items: center;

		@include tablet-down {
			background-image: url("../img/bg/nn-tour-mobile.jpg");
			background-position: center top;
			background-size: 100% auto;
			padding-top: 95%;
			background-color: #07152a;
		}

		@media screen and (min-width: 1500px) {
			background-position: top left;
		}

		.container {
			display: flex;
			justify-content: flex-end;
			padding-top: 100px;
			padding-bottom: 100px;
			max-height: 70vw;
			overflow-y: hidden;

			@include tablet-down {
				max-height: none;
			}

			.content {
				color: $color-white;
				text-align: center;
				width: 44%;
				overflow-y: auto;

				// Styling for Webkit browsers: Chrome, Safari, Edge
				&::-webkit-scrollbar {
					width: 8px; // Thin scrollbar
				}

				&::-webkit-scrollbar-track {
					background-color: #2c2c2c; // Dark grey track
				}

				&::-webkit-scrollbar-thumb {
					background-color: #000; // Black scrollbar thumb
					border-radius: 4px; // Slightly rounded corners
				}

				&::-webkit-scrollbar-thumb:hover {
					background-color: #4c4c4c; // Slightly lighter black when hovered
				}

				// Styling for Firefox
				scrollbar-width: thin; // 'thin' or 'auto' or 'none'
				scrollbar-color: #ffffff33 #2c2c2c33; // thumb and track color

				@include tablet-down {
					width: 100%;
				}

				.tour-wrapper {
					padding-bottom: 100px;
					width: 100%;
				}

				p {
					font-family: $font-oswald;
					font-weight: 500;
					text-transform: uppercase;
					display: block;
					margin-bottom: 10px;
				}
			}

		}
	}

	section.tour-page {
		background-image: url("../img/bg/nn-tour.jpg");
		background-position: top left;
		background-repeat: no-repeat;
		background-size: 100% auto;
		min-height: 100vh;

		@include tablet-down {
			background-image: url("../img/bg/nn-tour-mobile.jpg");
			background-position: center top;
			background-size: 100% auto;
			padding-top: 95%;
			background-color: #07152a;
		}

		@media screen and (min-width: 1500px) {
			background-position: top left;
		}

		.container {
			display: flex;
			justify-content: flex-end;
			padding-top: 100px;
			padding-bottom: 100px;
			max-height: 70vw;
			overflow-y: hidden;

			@include tablet-down {
				max-height: none;
			}

			.content {
				color: $color-white;
				text-align: center;
				width: 44%;
				overflow-y: auto;

				// Styling for Webkit browsers: Chrome, Safari, Edge
				&::-webkit-scrollbar {
					width: 8px; // Thin scrollbar
				}

				&::-webkit-scrollbar-track {
					background-color: #2c2c2c; // Dark grey track
				}

				&::-webkit-scrollbar-thumb {
					background-color: #000; // Black scrollbar thumb
					border-radius: 4px; // Slightly rounded corners
				}

				&::-webkit-scrollbar-thumb:hover {
					background-color: #4c4c4c; // Slightly lighter black when hovered
				}

				// Styling for Firefox
				scrollbar-width: thin; // 'thin' or 'auto' or 'none'
				scrollbar-color: #ffffff33 #2c2c2c33; // thumb and track color

				@include tablet-down {
					width: 100%;
				}

				.tour-wrapper {
					padding-bottom: 100px;
					width: 100%;
				}

				p {
					font-family: $font-oswald;
					font-weight: 500;
					text-transform: uppercase;
					display: block;
					margin-bottom: 10px;
				}
			}

		}
	}
}

footer {
	background-color: $color-black;
	padding: 40px 0;

	.container {
		.side {
			&:first-child {
				margin-bottom: 40px;

				h2 {
					text-align: center;
					margin-bottom: 20px;
				}

				.social {
					@include flex(row, center, center);
					gap: 20px;

					a {
						font-size: 20px;
					}

					.side {
						margin: 0;

						&:last-child {
							a {
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}

			&:last-child {
				.newsletter {
					margin-bottom: 40px;

					p {
						text-align: center;
						margin-bottom: 20px;
						font-weight: 500;
						letter-spacing: .3em;
						font-size: 18px;
					}

					a {
						display: block;
						margin: 0 auto;
						margin-bottom: 20px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}

				.copyright {
					font-size: 9px;
					text-align: center;
					text-transform: uppercase;
					line-height: 1.6em;

					a {
						@include hover-focus {
							color: $color-black;
						}
					}
				}
			}
		}
	}

	@include tablet-up {
		padding: 120px 0;

		.container {
			@include flex(row, space-between, inherit);
			flex-wrap: wrap;
			gap: 0;

			.side {
				max-width: unset;
				flex: 1;

				&:first-child {
					padding: 40px 40px 40px 0;
					margin: 0;
					border-right: 1px solid $color-gray;

					h2 {
						font-size: 100px !important;
					}

					.social {
						flex: 1;
						gap: 40px;

						a {
							font-size: 30px;
							width: unset;
						}
					}
				}

				&:last-child {
					@include flex(column, center, flex-end);
					padding: 40px 0 40px 40px;

					.newsletter {
						p {
							text-align: left;
						}

						.buttons {
							@include flex(row, space-between, center);

							a {
								width: calc(50% - 10px);
								margin: 0;
							}
						}
					}

					.copyright {
						p {
							text-align: right;
						}
					}
				}
			}
		}
	}
}

.subs-popup {
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.97);
	z-index: 9999;
	top: 0;
	left: 0;
	transition: all .3s;
	transform: scale(0.92);
	pointer-events: none;
	opacity: 0;

	&.active {
		transform: scale(1.0);
		pointer-events: all;
		opacity: 1;
	}

	.container {
		iframe {
			width: 100%;
			max-width: 800px;
			margin: 0 auto;
			height: 800px;
			padding-top: 80px;
			height: 94vh;
		}

		.subs-popup-close {
			position: absolute;
			background-color: $color-red;
			top: 0;
			right: 0;
			font-size: 16px;
			padding: 20px;
		}
	}

	@include tablet-up {
		.container {
			padding-top: 60px;

			iframe {
				padding-top: 0;
			}
		}
	}
}

.section-heading {
	font-family: $font-display !important;
	letter-spacing: 6px !important;
	font-size: 60px !important;
	font-weight: 400 !important;
	margin-bottom: 30px !important;

	@include tablet-up {
		font-size: 150px !important;
		margin-bottom: 60px !important;
	}
}

#seated-55fdf2c0 {
	.seated-events-table {
		border-top: none;

		// row = item
		.seated-event-row {
			border-bottom: none;
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10px;

			// @include tablet-down {
			// 	align-items: center;
			// }

			.seated-event-description-cells {
				display: flex;
				justify-content: space-between;
				text-align: left;
				font-size: .8rem;
				align-items: center;
				flex: 4;
				max-width: 72%;
				gap: 8px;

				// @include tablet-down {
				// 	gap: 0;
				// 	flex-direction: column;
				// }

				.seated-event-date-cell {
					flex: 1;

					// @include tablet-down {
					// 	margin-bottom: 10px;
					// }
				}

				.seated-event-venue-cell {
					display: flex;
					flex-direction: row;
					gap: 20px;
					flex: 2;

					// @include tablet-down {
					// 	// justify-content: flex-start;
					// 	gap: 10px;
					// 	margin-bottom: 10px;
					// }

					.seated-event-venue-name {
						flex: 1;

						// @include tablet-down {
						// 	text-align: center;
						// }
					}

					.seated-event-venue-location {
						flex: 1;

						// @include tablet-down {
						// 	text-align: center;
						// }
					}

					.seated-event-details-cell {}
				}

			}

			.seated-event-link-cells {
				margin: 0;
			}


			a.seated-event-link {
				font-size: .7rem;
				font-weight: 700;
				color: $color-white;
				border: 2px solid $color-white;
				background-color: #00000000;
				flex: 1;
			}
		}
	}
}