.btn{
    display: inline-block;
    font-size: 16px;
    text-transform: uppercase;
    width: 216px;
    max-width: 100%;
    text-align: center;
    padding: 12px 0;
    color: $color-white;
    position: relative;
    border-bottom: solid 2px;
}

.btn-black{
    border-color: $color-black;
    @include hover-focus{
        color: $color-black;
    }
}
.btn-red{
    border-color: $color-white;
    @include hover-focus{
        color: $color-gray;
    }
}