$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$color-red: #990610;
$color-red-light: #a21a21; 
$color-gray: #2C2C2C;
$font-bebas: 'Bebas Neue', cursive;
$font-oswald: 'Oswald', sans-serif;
// $font-display: 'Bastrad Vol.02';
$font-display: 'a Another Tag';